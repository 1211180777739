import * as Types from '@/generated';
import { usePage } from '@inertiajs/vue3';
import { computed, ComputedRef } from 'vue';
import { Membership, Team } from '@/generated';

type GlobalProps = { [T in keyof Types.GlobalProps]: ComputedRef<Types.GlobalProps[T]>; }
type AuthGlobalProps = { team: ComputedRef<Team>, membership: ComputedRef<Membership> }

export function useGlobalPropsUnauthenticated(): GlobalProps {
  const page = usePage<Types.GlobalProps>();

  const computedProps: any = {};

  Object.keys(page.props).forEach(propName => {
    computedProps[propName] = computed(() => page.props[propName]);
  });

  return computedProps;
}

export function useGlobalProps(): GlobalProps & AuthGlobalProps {
  return useGlobalPropsUnauthenticated() as any;
}
